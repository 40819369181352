import firebase from 'firebase'

export const firebaseConfig = {
  apiKey: 'AIzaSyCkQhLkWK12LFXN1x_2ilUYDwnfVHawsEc',
  authDomain: 'savetrip.me',
  databaseURL: 'https://api-7414026823583742738-802346.firebaseio.com',
  projectId: 'api-7414026823583742738-802346',
  storageBucket: 'api-7414026823583742738-802346.appspot.com',
  messagingSenderId: '829765965524',
  appId: '1:829765965524:web:6607eb672e1506c9eb4d41',
  measurementId: 'G-S332TPD9GL'
}

export const firebaseApp = firebase.initializeApp(firebaseConfig)

firebaseApp.analytics()

