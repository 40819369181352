/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useRef, useState } from 'react'
import qs from 'qs'
import { Grid, Button, Card } from '@material-ui/core'
import stlogo from '../../image/savetrip_letter_color_ns.png'
import agoda from '../../image/agoda.svg'
import airalo from '../../image/airalo.svg'
import '../../styles/index.css'

type TextType = {
  topBanner: string,
  hotelTitle: string,
  agodaButton: string,
  agodaButtonLink: string,
  simCardTitle: string,
  airaloButton: string,
  airaloButtonLink: string
}

const textKo: TextType = {
  topBanner: '여행을 준비해 보세요!',
  hotelTitle: '호텔',
  agodaButton: '특별 할인 쿠폰 받고 호텔 예약 하기',
  agodaButtonLink: 'https://www.agoda.com/deals?pcs=1&cid=1818864&hl=en&pslc=1',
  simCardTitle: '심카드',
  airaloButton: '편리한 eSIM 구매 하기(15% 할인)',
  airaloButtonLink: 'https://discover.airalo.com/savetrip/'
}

const textEn: TextType = {
  topBanner: 'Get ready to go on a trip!',
  hotelTitle: 'Hotel',
  agodaButton: 'Get special coupons',
  agodaButtonLink: 'https://www.agoda.com/deals?pcs=1&cid=1818864&hl=en&pslc=1',
  simCardTitle: 'SIM Card',
  airaloButton: 'Buy eSIM (15% discount)',
  airaloButtonLink: 'https://discover.airalo.com/savetrip_en/'
}


export default function AffiliateMainPage() {
  const params = qs.parse(location.search, { ignoreQueryPrefix: true })
  const tcc = params.tcc as string
  const startDate = params.st as string
  const endDate = params.ed as string
  const city = params.city as string
  const locale = params.locale as string  //lang-country
  console.log(params)

  let text: TextType

  if(locale && locale.startsWith('ko')) {
    text = textKo
  } else {
    text = textEn
  }

  return (
    // use absolute for full screen
    <div className='double-gradient' style={{ flexDirection: 'column', display: 'flex', padding: 10, backgroundColor: 'gray', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
      {/* <div style={{ width: '100%' }}>
        <img src={stlogo} style={{ width: '25%', marginLeft: 10, marginTop: 8, objectFit: 'contain' }} />
      </div> */}
      <div style={{ width: '100%', textAlign: 'center', fontSize: 22, fontWeight: 'bold', marginTop: 20, marginBottom: 20 }}>
        {text.topBanner}
      </div>
      <Card variant='outlined' style={{ width: '100%', marginTop: 15 }}>
        <div style={{ fontSize: 20, fontWeight: 'bold', color: '#333333', padding: 10 }}>
          # {text.hotelTitle}
        </div>
        <div>
          <img src={agoda} width='15%' height='15%' style={{ marginLeft: 13 }} />
          <Button variant='outlined' href={text.agodaButtonLink} style={{ marginBottom: 15, marginLeft: 36 }}>{text.agodaButton}</Button>
          {/* <Button href='https://www.agoda.com/partners/partnersearch.aspx?pcs=1&cid=1818864&hl=en-us&city=16440&checkIn=2023-10-14&checkOut=2023-10-15'>다낭 호텔 검색</Button> */}
        </div>
      </Card>
      <Card variant='outlined' style={{ width: '100%', marginTop: 15 }}>
        <div style={{ fontSize: 20, fontWeight: 'bold', color: '#333333', padding: 10 }}>
          # {text.simCardTitle}
        </div>
        <div>
          <img src={airalo} width='20%' height='20%' style={{ marginLeft: 10 }} />
          <Button variant='outlined' href={text.airaloButtonLink} style={{ marginBottom: 11, marginLeft: 20 }}>{text.airaloButton}</Button>
        </div>
      </Card>

    </div>
  )
}